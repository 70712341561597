<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("BRAND.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3
                        v-if="$route.params.id"
                        class="card-label font-weight-bolder text-dark"
                    >
                        {{
                            edit_mode
                                ? add_new
                                    ? $t("QRCHARGE.NEW")
                                    : $t("QRCHARGE.EDIT")
                                : $t("QRCHARGE.VIEW")
                        }}
                    </h3>
                    <h3 v-else class="card-label font-weight-bolder text-dark">
                        {{ edit_mode ? $t("ABOUT.EDIT") : $t("ABOUT.ABOUT") }}
                    </h3>
                    <span
                        class="text-muted font-weight-bold font-size-sm mt-1"
                        >{{ qrcharge.name }}</span
                    >
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                        v-on:click="save()"
                        class="btn btn-primary px-15 mr-2"
                        id="brand_save"
                        >{{ $t("SAVE") }}
                    </a>
                    <a
                        v-on:click="cancel()"
                        class="btn btn-secondary px-15 mr-2"
                        >{{ $t("CANCEL") }}
                    </a>
                    <div v-if="!add_new">
                        <a
                            v-on:click="downloadCSVData(qrcharge.id)"
                            class="btn btn-secondary"
                        >
                            {{ $t("QRCHARGE.EXPORTCSV") }}
                        </a>
                    </div>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                        >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="brand_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("QRCHARGE.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="name"
                                        name="name"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="qrcharge.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("QRCHARGE.ORDER_NUMBER") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="order_number"
                                        name="order_number"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="qrcharge.order_number"
                                    />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("QRCHARGE.MANUFACTURER") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <select
                                        ref="manufacturer_id"
                                        name="manufacturer_id"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="qrcharge.manufacturer_id"
                                    >
                                        <option v-bind:key="null" :value="null">
                                            {{ $t("QRCHARGE.NOMANUFACTURER") }}
                                        </option>

                                        <option
                                            v-for="level in manufacturers"
                                            v-bind:key="level.id"
                                            :selected="level.id == 2"
                                            :value="level.id"
                                        >
                                            {{ level.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("QRCHARGE.COMMENT") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="comment"
                                        name="comment"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="qrcharge.comment"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("QRCHARGE.AMOUNT") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        :disabled="!add_new"
                                        ref="amount"
                                        name="amount"
                                        class="form-control form-control-md form-control-solid"
                                        type="number"
                                        v-model="qrcharge.amount"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"

import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger"
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap"
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core"
import KTUtil from "@/assets/js/components/util"

export default {
    name: "qrcharge",
    data() {
        return {
            add_new: false,
            edit_mode: false,
            not_found: false,
            qrexport: [],
            amount: 10000,
            default_logo: "media/images/placeholder.png",
            current_logo: null,
            new_logo: {},
            original_brand: null,
            manufacturers: null,
            qrcharge: {
                name: "",
                order_number: "",
                amount: 1000,
                comment: "",
                brand_id: 0,
                brand_name: "",
                creation_date: "",
                manufacturer_id: null,
            },
        }
    },
    mounted() {
        if (this.$route.params.id == "new") {
            this.add_new = true
            this.edit_mode = true

            ApiService.get("manufacturer").then((data) => {
                this.manufacturers = data.data
            })

            this.$store.dispatch(SET_BREADCRUMB, [
                { title: this.$t("QRCODE"), route: "/QRCHARGE" },
                { title: this.$t("QRCHARGE.NEW") },
            ])
        } else {
            ApiService.get("manufacturer").then((data) => {
                this.manufacturers = data.data
            })

            ApiService.get(
                this.$route.params.id
                    ? "qrcharge/" + this.$route.params.id
                    : "qrcharge"
            )
                .then((data) => {
                    this.original_brand = data.data

                    this.qrcharge = JSON.parse(
                        JSON.stringify(this.original_brand)
                    )

                    if (this.$route.params.id) {
                        this.$store.dispatch(SET_BREADCRUMB, [
                            {
                                title: this.$t("QRCHARGE.TITLE"),
                                route: "/qrcharge",
                            },
                            { title: data.data.name },
                        ])
                    } else {
                        this.$store.dispatch(SET_BREADCRUMB, [
                            { title: this.$t("ABOUT.ABOUT") },
                        ])
                    }

                    if (this.$route.params.edit) {
                        this.edit_mode = true
                    }
                })
                .catch(() => {
                    this.not_found = true
                    this.$store.dispatch(SET_BREADCRUMB, [])
                })
        }

        this.fv = formValidation(KTUtil.getById("brand_form"), {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("QRCHARGE.NAME"),
                            }),
                        },
                    },
                },
                amount: {
                    validators: {
                        numeric: {
                            message: this.$t("VALIDATION.DIGITS", {
                                name: this.$t("QRCHARGE.AMOUNT"),
                            }),
                        },
                        greaterThan: {
                            min: 1,
                            message: this.$t("VALIDATION.LOW", {
                                name: this.$t("QRCHARGE.AMOUNT"),
                            }),
                        },
                    },
                },
                city: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.CITY"),
                            }),
                        },
                    },
                },
                zip: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.ZIP"),
                            }),
                        },
                    },
                },
                country: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.COUNTRY"),
                            }),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        edit() {
            this.edit_mode = true
        },
        cancel() {
            if (this.add_new) {
                this.$router.push({ name: "qrcharge" })
            } else {
                this.$router.push({ name: "qrcharge" })
            }
        },

        save() {
            this.fv.validate().then((status) => {
                if (status == "Valid") {
                    KTUtil.getById("brand_save").classList.add(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    )

                    if (this.add_new) {
                        ApiService.post("qrcharge", this.qrcharge)
                            .then((data) => {
                                this.qrcharge.id = data.data.id
                            })

                            .then(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.$router.push({ name: "qrcharge" })
                            })
                            .catch(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    } else {
                        ApiService.put(
                            this.$route.params.id
                                ? "qrcharge/" + this.qrcharge.id
                                : "qrcharge",
                            this.qrcharge
                        )
                            .then((data) => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.original_brand = data.data
                                this.cancel()
                            })
                            .catch(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    }
                }
            })
        },

        downloadCSVData: function (id) {
            ApiService.get("qrcodes/export/" + id)
                .then(({ data }) => {
                    this.qrexport = data
                })
                .catch(() => {
                    //Just keep it empty
                })
                .then(() => {
                    let csv = "Url\n"
                    this.qrexport.forEach((row) => {
                        // csv += row.join(',');

                        if (process.env.NODE_ENV == "development") {
                            csv += "https://d.xiphoo.com/?QID=" + row.code
                        } else {
                            if (process.env.NODE_ENV == "staging") {
                                csv += "https://a.xiphoo.com/?QID=" + row.code
                            } else {
                                csv += "https://s.xiphoo.com/?QID=" + row.code
                            }
                        }

                        csv += "\n"
                    })

                    const anchor = document.createElement("a")
                    anchor.href =
                        "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
                    anchor.target = "_blank"
                    anchor.download = "QRCODES_" + this.qrcharge.name + ".csv"
                    anchor.click()
                })
        },
    },
    computed: {},
}
</script>
